import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardMedia } from '@mui/material'

const LogoCard = () => {

  const [season, setSeason] = useState('summer'); // Default to summer

  const { t } = useTranslation()

  useEffect(() => {
    // Logic to determine the season
    const now = new Date();
    const month = now.getMonth();
    setSeason(month >= 3 && month <= 10 ? 'summer' : 'winter');
  }, []);

  return (
    <Card>
      <CardMedia
        sx={{ height: 300, mt: 1 }}
        title= {t('app.title')}
        image= {
          season === 'summer'? 
            "images/SliderPensiunSommer.jpg" :
            "images/SliderPensiunWinter.jpg"
        }
      />        
    </Card>
  )
}

export default LogoCard
