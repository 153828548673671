import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';

import NotFoundPage from './pages/NotFoundPage';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Home from './pages/Home';
import Photos from './pages/Photos';
import TheHouse from './pages/TheHouse';
import Info from './pages/Info';
import Footer from './components/Footer';

function App() {
  return (
    <Container>
      <CssBaseline />
      <Router>
        <ResponsiveAppBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/thehouse' element={<TheHouse />} />
          <Route path='info' element={<Info />} />
          <Route path='photos' element={<Photos />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Router>

      <Footer />

    </Container>
  );
}

export default App;
