import React, { useEffect } from 'react'
import { PaletteMode } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme  } from './darkTheme'
import { lightTheme } from './lightTheme'

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default  ColorModeContext

const themeMode = localStorage.getItem('themeMode')?
  localStorage.getItem('themeMode') as PaletteMode : 'dark'

type Props = {
  children?: React.ReactNode;
}

export const ColorModeProvider = ({children}: Props) => {
  const [mode, setMode] = React.useState<PaletteMode>(themeMode);
  
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

 const theme = React.useMemo(
    () => (mode === 'light' ? darkTheme : lightTheme)
    , [mode]
  )

  useEffect(() => {
    localStorage.setItem('themeMode', mode)
  }, [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
