import React, { ReactNode } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next';

type Props = {
  children?: ReactNode;
  variant?: string |  null
}


const StyledTableCell = ({children, variant}: Props) => {
  return (
    <TableCell
      sx={(theme) => ({
        background: (variant === 'head')? 
          theme.palette.primary.dark :
          theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
        textAlign: 'center'
      })}
    >
      {children}
    </TableCell>
  )
}

const PriceTable = () => {

  const { t } = useTranslation()

  return (
    <>      
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('')}</TableCell>
              <StyledTableCell variant="head">2-3 {t('priceTable.nights')}</StyledTableCell>
              <StyledTableCell variant="head">4-7 {t('priceTable.nights')}</StyledTableCell>
              <StyledTableCell variant="head">8+ {t('priceTable.nights')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell variant="head">
                8-14 {t('priceTable.persons')}
              </StyledTableCell>
              <StyledTableCell>35.- CHF</StyledTableCell>
              <StyledTableCell>30.- CHF</StyledTableCell>
              <StyledTableCell>25.- CHF</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell variant="head">
                15-21 {t('priceTable.persons')}
              </StyledTableCell>
              <StyledTableCell>34.- CHF</StyledTableCell>
              <StyledTableCell>29.- CHF</StyledTableCell>
              <StyledTableCell>23.- CHF</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell variant="head">
                22-28 {t('priceTable.persons')}
              </StyledTableCell>
              <StyledTableCell>32.- CHF</StyledTableCell>
              <StyledTableCell>27.- CHF</StyledTableCell>
              <StyledTableCell>21.- CHF</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell variant="head">
                29-36 {t('priceTable.persons')}
              </StyledTableCell>
              <StyledTableCell>29.- CHF</StyledTableCell>
              <StyledTableCell>24.- CHF</StyledTableCell>
              <StyledTableCell>19.- CHF</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>        
    </>
  )
}

export default PriceTable
