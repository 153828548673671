import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import LogoCard from '../../components/LogoCard'
import { renderDefault, renderAround, renderHistory } from './articles'

const TheHouse = () => {

  const { i18n } = useTranslation()

  const [textDefault, setTextDefault] = useState('...')
  const [textAround, setTextAround] = useState('...')
  const [textHistory, setTextHistory]= useState('...')

  useEffect(() => {
    fetch(renderDefault(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextDefault(textContent);
    })

    fetch(renderAround(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextAround(textContent);
    })

    fetch(renderHistory(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextHistory(textContent);
    })
  }, [i18n.language])

  return (
    <Grid 
      container
      // marginLeft={5}
      // marginRight={5}
      // justifyContent={'center'} 
      spacing={2}
    >
      <Grid item xs={12}>
        <LogoCard />
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textDefault}</Markdown>
        </Typography>
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textAround}</Markdown>
        </Typography>
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textHistory}</Markdown>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TheHouse
