import React from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

const settings = [
  {value: 'rm', label: 'Romontsch'}, 
  {value: 'de', label: 'Deutsch'}, 
  {value: 'en', label: 'English'}
]

const LangSelector = () => {

  const { i18n } = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    const lang_code = event.target.value
    i18n.changeLanguage(lang_code)
  }

  return (
    <FormControl size="small">
      <Select 
        size='small'
        labelId="language-select-label"
        id="language-select"
        value={i18n.language}
        autoWidth
        onChange={handleChange}
      >
        {settings.map((setting, idx) => (
          <MenuItem value={setting.value} key={idx}>
            <Typography variant='body2'>
              {setting.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LangSelector
