import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import {
  Phone as PhoneIcon,
  Mail as MailIcon,
} from '@mui/icons-material'

import { APP_VERSION } from './../utils/constants';

const Footer = () => {
  return (
    <>
      <Grid
        container
        sx={(theme) => ({
          mt: 5,
          backgroundColor: theme.palette.primary.main
        })}
      >
        <Grid item xs={12} sm={4} sx={{ textAlign:'center'}}>
          <Button
            href="mailto:info@pensiun-degonda.ch"
            startIcon={<MailIcon />}
            size="small"
            color='inherit'
            sx={{textTransform: 'none'}}
          >
            info@pensiun-degonda.ch
          </Button>
        </Grid>

        <Grid item xs sx={{ textAlign:'center'}}>
          <Button
            href="tel:+41797593222"
            startIcon={<PhoneIcon />}
            size="small"
            color='inherit'
            sx={{textTransform: 'none'}}
          >
            +41 79 759 32 22
          </Button>
        </Grid>

        <Grid item xs sx={{ textAlign:'center'}}>
          <Button
            href="tel:+41794730132"
            startIcon={<PhoneIcon />}
            size="small"
            color='inherit'
            sx={{textTransform: 'none'}}
          >
            +41 79 473 01 32
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ textAlign:'center'}}>
          <Typography variant="caption" align="center">
            © 2024 Pensiun Degonda ({APP_VERSION})
            {/* Designed by Marionna & Guido Lombriser. */}
          </Typography>
        </Grid>

      </Grid>
    </>
  )
}

export default Footer
