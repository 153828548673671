import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { Bed as LogoIcon} from '@mui/icons-material'
import LangSelector from './LangSelector';
import { useTranslation } from 'react-i18next';

import TheHouseIcon from '@mui/icons-material/House'
import InfoIcon from '@mui/icons-material/Info'
import PhotoIcon from '@mui/icons-material/Photo'
import ColorModeSwitch from './ColorModeSwitch';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation()

  const pages = [
    { label: t('appBar.TheHouse'), href: '/thehouse', icon: <TheHouseIcon />},
    { label: t('appBar.Info'), href: '/info', icon: <InfoIcon />},
    { label: t('appBar.Fotos'), href: 'photos', icon: <PhotoIcon />}
  ]

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color='primary' >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LogoIcon
            sx={(theme) => ({ 
              display: { xs: 'none', md: 'flex' }, 
              mr: 1, 
              color: theme.palette.primary.contrastText
            })}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              // fontFamily: 'monospace',
              fontWeight: 700,
              // letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('appBar.Logo')}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, idx) => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Link key={idx} to={page.href} style={{color: 'inherit'}}>
                    <Button
                      sx={{ color: 'inherit' }}
                      startIcon={page.icon}
                    >
                      {page.label}
                    </Button>
                  </Link>
                </MenuItem>
              ))}

              <Divider />
              <MenuItem key='gen' onClick={handleCloseNavMenu}>
                <LangSelector />
              </MenuItem>

            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('appBar.Logo')}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, idx) => (
              <Link key={idx} to={page.href} style={{color: 'inherit'}}>
                <Button
                  key={page.label}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'inherit' }}
                >
                  {page.label}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <LangSelector />
          </Box>
          <Box>
            <ColorModeSwitch />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
