import React from 'react'
import { Grid } from '@mui/material'
import PhotoCarousel from './PhotoCarousel'

const Photos = () => {
  return (
    <Grid container sx={{mt: 2, mb: 10}}>
      <Grid item xs={0} sm={1} md={2} />
      <Grid item xs={12} sm={10} md={8}>
        <PhotoCarousel /> 
      </Grid>
      <Grid item xs={0} sm={1} md={2} />
    </Grid>
  )
}

export default Photos
