const Default_en = require('./default/en/index.md')
const Default_de = require('./default/de/index.md')
const Default_rm = require('./default/rm/index.md')

const Meals_en = require('./meals/en/index.md')
const Meals_de = require('./meals/de/index.md')
const Meals_rm = require('./meals/rm/index.md')

const Anreise_en = require('./Anreise/en/index.md')
const Anreise_de = require('./Anreise/de/index.md')
const Anreise_rm = require('./Anreise/rm/index.md')

const Apartment_en = require('./apartment/en/index.md')
const Apartment_de = require('./apartment/de/index.md')
const Apartment_rm = require('./apartment/rm/index.md')

const Availability_en = require('./availability/en/index.md')
const Availability_de = require('./availability/de/index.md')
const Availability_rm = require('./availability/rm/index.md')

const FloorPlan_en = require('./floorplan/en/index.md')
const FloorPlan_de = require('./floorplan/de/index.md')
const FloorPlan_rm = require('./floorplan/rm/index.md')

const Shopping_en = require('./shopping/en/index.md')
const Shopping_de = require('./shopping/de/index.md')
const Shopping_rm = require('./shopping/rm/index.md')

const Prices_en = require('./prices/en/index.md')
const Prices_de = require('./prices/de/index.md')
const Prices_rm = require('./prices/rm/index.md')

export const renderDefault = (language: string) => {
  switch (language) {
    case 'de':
      return Default_de;
    case 'rm':
      return Default_rm;
    default:
      return Default_en;
  }
};

export const renderMeals = (language: string) => {
  switch (language) {
    case 'de':
      return Meals_de;
    case 'rm':
      return Meals_rm;
    default:
      return Meals_en;
  }
};

export const renderAnreise = (language: string) => {
  switch (language) {
    case 'de':
      return Anreise_de;
    case 'rm':
      return Anreise_rm;
    default:
      return Anreise_en;
  }
};

export const renderApartment = (language: string) => {
  switch (language) {
    case 'de':
      return Apartment_de;
    case 'rm':
      return Apartment_rm;
    default:
      return Apartment_en;
  }
};

export const renderAvailability = (language: string) => {
  switch (language) {
    case 'de':
      return Availability_de;
    case 'rm':
      return Availability_rm;
    default:
      return Availability_en;
  }
};

export const renderFloorPlan = (language: string) => {
  switch (language) {
    case 'de':
      return FloorPlan_de;
    case 'rm':
      return FloorPlan_rm;
    default:
      return FloorPlan_en;
  }
};

export const renderShopping = (language: string) => {
  switch (language) {
    case 'de':
      return Shopping_de;
    case 'rm':
      return Shopping_rm;
    default:
      return Shopping_en;
  }
};

export const renderPrices = (language: string) => {
  switch (language) {
    case 'de':
      return Prices_de;
    case 'rm':
      return Prices_rm;
    default:
      return Prices_en;
  }
};
