import React, { useContext } from 'react'
import { IconButton, useTheme } from '@mui/material'
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

import ColorModeContext from '../context/ColorModeContext'


const ColorModeSwitch = () => {

  const colorMode = useContext(ColorModeContext)
  const theme = useTheme()

  return (
    <>
      <IconButton 
        sx={{ ml: 1 }} 
        color="inherit"
        onClick={colorMode.toggleColorMode} 
      >
          {theme.palette.mode === 'dark' ? 
            <WbSunnyRoundedIcon />
          : <ModeNightRoundedIcon />
          }
      </IconButton>      
    </>
  )
}

export default ColorModeSwitch
