import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import {
  // Phone as PhoneIcon,
  Hotel as HotelIcon,
  Mail as MailIcon,
} from '@mui/icons-material'

import {
  renderDefault,
  renderAvailability,
  renderFloorPlan,
  renderAnreise,
  renderMeals,
  renderShopping,
  renderApartment,
  renderPrices
} from './articles'

import LogoCard from '../../components/LogoCard'
import PriceTable from './PriceTable'

const Info = () => {

  const { t, i18n } = useTranslation()

  const [textDefault, setTextDefault] = useState('...')
  const [textAvailability, setTextAvailability] = useState('...')
  const [textFloorPlan, setTextFloorPlan] = useState('...')
  const [textAnreise, setTextAnreise] = useState('...')
  const [textMeals, setTextMeals] = useState('...')
  const [textShopping, setTextShopping] = useState('...')
  const [textApartment, setTextApartment] = useState('...')
  const [textPrices, setTextPrices] = useState('...')
  
  useEffect(() => {
    fetch(renderDefault(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextDefault(textContent);
    })

    fetch(renderAvailability(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextAvailability(textContent);
    })

    fetch(renderFloorPlan(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextFloorPlan(textContent);
    })

    fetch(renderAnreise(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextAnreise(textContent);
    })

    fetch(renderMeals(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextMeals(textContent);
    })

    fetch(renderShopping(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextShopping(textContent);
    })

    fetch(renderApartment(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextApartment(textContent);
    })

    fetch(renderPrices(i18n.language))
    .then((response) => response.text())
    .then((textContent) => {
      setTextPrices(textContent);
    })

  }, [i18n.language])

  return (
    <Grid container spacing={2} flexGrow={1}>
      <Grid item xs={12}>
        <LogoCard />
      </Grid>

      {/* Gruppenhaus */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textDefault}
          </Markdown>
        </Typography>
      </Grid>

      {/* Belegungsplan */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}          
          >
            {textAvailability}
          </Markdown>
        </Typography>
      </Grid>

      {/* Hausplan */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textFloorPlan}</Markdown>
        </Typography>
        <Button
          href="data/ZimmerCombrasRooms.pdf"
          target="_blank"
          sx={{textTransform: 'none', color: 'inherit'}}
        >
          <HotelIcon fontSize="small" />
          &nbsp;{t('infoPage.floorPlan')}
        </Button>
      </Grid>

      {/* Anreise */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textAnreise}</Markdown>
        </Typography>
      </Grid>

      {/* Halbpension/Mahlzeiten */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown>{textMeals}</Markdown>
        </Typography>
      </Grid>

      {/* Einkäufe */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textShopping}
          </Markdown>
        </Typography>
      </Grid>

      {/* Ferienwohnung */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textApartment}
          </Markdown>
        </Typography>
      </Grid>

      {/* Addresse */}
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <h2>{t('infoPage.address')}</h2>
        <MailIcon />
        <br />
        <Typography>Pensiun Degonda</Typography>
        <Typography>Via Curschellas 2</Typography>
        <Typography>CH-7176 Cumpadials</Typography>
      </Grid>

      {/* Preise */}
      <Grid item xs={12} sx={{ textAlign:'center'}}>
        <Typography>
          <Markdown>{textPrices}</Markdown>
        </Typography>
      </Grid>

      <Grid item xs={0} sm={1} md={2} />

      <Grid item xs={12} sm={10} md={8}>
        <PriceTable />
      </Grid>

      <Grid item xs={0} sm={1} md={2} />

    </Grid>
  )
}

export default Info
