import React from 'react'
import Carousel from 'react-material-ui-carousel';

import { photos } from './photos'
import { Box } from '@mui/material';

const PhotoCarousel = () => {

  return (
    <Carousel
      animation="slide"
      navButtonsAlwaysVisible
    >
      {photos.map((tile, idx) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }} 
        >
          <img
            src={'images/' + tile.name + '.' + tile.type}
            alt={tile.title}
            // width="500"
            height="400"
          />
        </Box>
      ))}
    </Carousel>
  )
}

export default PhotoCarousel
