export const photos = [
  {
    name: 'haus-sommer',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'haus-winter',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 3,
  },
  {
    name: 'combra1',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'combra2',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'combra3',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'combra4',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'combra5',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'combra6',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'sala1',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'sala2',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'sala3',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'sala4',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'cuschina3',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'cuschina1',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'cuschina2',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
  {
    name: 'stiva1',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'stiva2',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 2,
  },
  {
    name: 'haus-garten',
    type: 'jpg',
    title: 'Image',
    author: 'author',
    cols: 1,
  },
];
