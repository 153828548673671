import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: grey[100]
    },
    text: {
      primary: grey[700],
    }
  },
})
