const Default_en = require('./default/en/index.md')
const Default_de = require('./default/de/index.md')
const Default_rm = require('./default/rm/index.md')

export const renderDefault = (language: string) => {
  switch (language) {
    case 'de':
      return Default_de;
    case 'rm':
      return Default_rm;
    default:
      return Default_en;
  }
};
