const TheHouseDefault_en = require('./default/en/index.md')
const TheHouseDefault_de = require('./default/de/index.md')
const TheHouseDefault_rm = require('./default/rm/index.md')


const TheHouseAround_en = require('./around/en/index.md')
const TheHouseAround_de = require('./around/de/index.md')
const TheHouseAround_rm = require('./around/rm/index.md')

const TheHouseHistory_en = require('./history/en/index.md')
const TheHouseHistory_de = require('./history/de/index.md')
const TheHouseHistory_rm = require('./history/rm/index.md')


export const renderDefault = (language: string) => {
  switch (language) {
    case 'de':
      return TheHouseDefault_de;
    case 'rm':
      return TheHouseDefault_rm;
    default:
      return TheHouseDefault_en;
  }
};

export const renderAround = (language: string) => {
  switch (language) {
    case 'de':
      return TheHouseAround_de;
    case 'rm':
      return TheHouseAround_rm;
    default:
      return TheHouseAround_en;
  }
};

export const renderHistory = (language: string) => {
  switch (language) {
    case 'de':
      return TheHouseHistory_de;
    case 'rm':
      return TheHouseHistory_rm;
    default:
      return TheHouseHistory_en;
  }
};
