import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import LogoCard from '../../components/LogoCard'
import { renderDefault } from './articles';

 
const Home = () => {

  const [textDefault_1, setTextDefault_1] = useState('...')
  const [textDefault_2, setTextDefault_2] = useState('...')
  const [textDefault_3, setTextDefault_3] = useState('...')

  const { i18n } = useTranslation()

  useEffect(() => {
    let lang = ['en', 'de', 'rm']

    switch (i18n.language) {
      case 'de':
        lang = ['de', 'rm', 'en']
        break
      case 'rm':
        lang = ['rm', 'de', 'en']
        break
      default:
        lang = ['en', 'de', 'rm']
      }

    fetch(renderDefault(lang[0]))
    .then((response) => response.text())
    .then((textContent) => {
      setTextDefault_1(textContent)
    })

    fetch(renderDefault(lang[1]))
    .then((response) => response.text())
    .then((textContent) => {
      setTextDefault_2(textContent)
    })

    fetch(renderDefault(lang[2]))
    .then((response) => response.text())
    .then((textContent) => {
      setTextDefault_3(textContent)
    })
  }, [i18n.language])

  return (
    <Grid container spacing={2} flexGrow={1}>
      <Grid item xs={12}>
        <LogoCard />
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textDefault_1}
          </Markdown>
        </Typography>
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textDefault_2}
          </Markdown>
        </Typography>
      </Grid>
      <Grid item xs sx={{ textAlign:'center', minWidth: 300}}>
        <Typography>
          <Markdown
            components={{
              a(props){
                const {node, ...rest} = props
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                return <a style={{color:'inherit', textDecoration:'none'}} {...rest}/>
              }
            }}
          >
            {textDefault_3}
          </Markdown>
        </Typography>
      </Grid>

      </Grid>
  )
}

export default Home
